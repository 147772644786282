


import { Component, Prop, Vue } from 'vue-property-decorator';
import EwSearchInput from '@/_modules/standalone-company/components/UI/ew-search-input/ew-search-input.vue';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';

@Component({
  components: {
    EwSearchInput,
    EwButton,
  },
})
export default class SearchBar extends Vue {

  public searchValue: string | string[] = '';

  @Prop({ type: Boolean, default: true })
  public readonly isFilterSearchDisabled: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly isFilterSearchHidden: boolean;

  public mounted(): void {
    this.searchValue = this.$route.query.search;
  }

}
