<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_186" data-name="Group 186" transform="translate(-588 -160)">
      <g id="Icon_feather-minimize-2" data-name="Icon feather-minimize-2" transform="translate(590.5 162.5)">
        <path id="Path_294" :style="style" data-name="Path 294" class="cls-1" d="M6,21H8.1v2.1" transform="translate(-1.15 -10.948)"/>
        <path id="Path_295" :style="style" data-name="Path 295" class="cls-1" d="M23.1,8.1H21V6" transform="translate(-10.948 -1.15)"/>
        <path id="Path_296" :style="style" data-name="Path 296" class="cls-1" d="M21,6.948,23.448,4.5" transform="translate(-10.948)"/>
        <path id="Path_297" :style="style" data-name="Path 297" class="cls-1" d="M4.5,23.448,6.948,21" transform="translate(0 -10.948)"/>
      </g>
      <g id="Ellipse_42" data-name="Ellipse 42" class="cls-2" transform="translate(588 160)" :style="style">
        <circle class="cls-3" cx="11" cy="11" r="11"/>
        <circle class="cls-4" cx="11" cy="11" r="10.5"/>
      </g>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconZoomOut = Vue.extend({
  name: 'icon-zoom-out',
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    style() {
      return `stroke: ${this.color}; fill:${this.color}}`;
    },
  },
});

export default IconZoomOut;
</script>

<style scoped lang="scss">
  /* stylelint-disable */
  .cls-1,
  .cls-2,
  .cls-4 {
    fill: none;

  }

  .cls-1,
  .cls-2 {
    stroke: #000;
  }

  .cls-1 {
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .cls-3 {
    stroke: none;
  }
</style>
