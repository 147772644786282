


import { Component, Vue, Watch } from 'vue-property-decorator';
import newsThumbnail from '@/_modules/news/components/news-thumbnail/news-thumbnail.vue';
import iconNews from '@/_modules/icons/components/sidebar/icon-news.vue';
import { TNewsIssue } from '@/_types/news-issue.type';
import newsApi from '@/_api/news.api';
import _cloneDeep from 'lodash.clonedeep';

@Component({
  components: {
    newsThumbnail,
    iconNews
  }
})
export default class NewsListLobby extends Vue {

  public newsList: TNewsIssue[] = [];
  public newsSlides: TNewsIssue[] = [];
  public isListLoading: boolean = false;
  public activeNewsSlideNumber: number = 0;
  public newsQuantityInSlide: number = 3;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get newsIssueId(): number {
    return this.$route.params.newsIssueId ? parseInt(this.$route.params.newsIssueId, 10) : null;
  }

  public get activeNewsSlide(): number {
    return this.activeNewsSlideNumber;
  }

  public set activeNewsSlide(value: number) {

    this.activeNewsSlideNumber = value;
  }

  public get isNewsListEmpty(): boolean {
    if (!this.newsList) {
      return true;
    }
    return this.newsSlides.length === 0;
  }

  @Watch('newsIssueId')
  public onNewsIssueIdChange(): void {
    this.scrollToCurrentNewsIssue();
  }

  @Watch('newsList')
  public onNewsListChange(): void {
    this.scrollToCurrentNewsIssue();
    this.newsListFiltered();
  }

  public setNewsSlide(direction: string): void {
    const dir: number = direction === 'prev' ? -1 : 1;
    let newIndex: number = this.activeNewsSlide + dir;
    newIndex = newIndex < 0 ? this.newsSlides.length - 1 : newIndex;
    newIndex = newIndex > this.newsSlides.length - 1 ? 0 : newIndex;

    this.activeNewsSlideNumber = newIndex;
  }

  public newsListFiltered(): void {
    const newsListFiltered = this.newsList.filter((newsIssue) => newsIssue.is_published);
    const result: any[] = [];
    if (newsListFiltered && newsListFiltered.length) {
      let amountOfSlides = 1; // Let there be at least one slide

      // If it makes sense, lets calculate the amount of slides
      if (newsListFiltered.length > this.newsQuantityInSlide) {
        amountOfSlides = Math.ceil(newsListFiltered.length / this.newsQuantityInSlide);
      }

      // Using the amount of slides to create the array of dummy slides
      for (let i = 0; i < amountOfSlides; i++) {
        result.push([...newsListFiltered.slice(i * this.newsQuantityInSlide, i * this.newsQuantityInSlide + this.newsQuantityInSlide)]);
      }

      // Outputting the result into the data property
      this.newsSlides = _cloneDeep(result);
    }
  }

  public mounted(): void {
    this.getNewsList();
  }

  private scrollToCurrentNewsIssue(): void {
    if (!this.newsIssueId) {
      return;
    }

    this.$nextTick(() => {
      const GRID_GAP = 18;
      const openNewsIssueIndex: number = this.newsListFiltered.length === 0 ? 0 : this.newsList.findIndex((newsIssue) => newsIssue.id === this.newsIssueId);

      if(this.$refs.newsListItems) {
        const openNewsListItem: Element = (this.$refs.newsListItems as Element[])[openNewsIssueIndex];
        if (!openNewsListItem) {
          return;
        }
        const rectItem: DOMRect = openNewsListItem.getBoundingClientRect();
        this.$emit('scrollTopUpdated', (rectItem.height + GRID_GAP) * openNewsIssueIndex);
      }
    });
  }

  private async getNewsList(): Promise<void> {
    this.isListLoading = true;
    this.newsList = await newsApi.getEventNews({ eventId: this.eventId, onlyPublished: true, offset: 0, limit: 100 });
    this.isListLoading = false;
  }

}
